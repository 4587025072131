.container {
  width: 100%;
  margin-top: 1.5em;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}

.container .title {
  display: block;
  font-size: var(--font-size--medium);
  font-weight: 700;
  margin-top: 8px;
  margin-bottom: 8px;
  margin-right: 1em;
  text-align: right;
  min-width: 10rem;
}

.container .inputs {
  flex-grow: 1;
  max-width: 22em;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
}

.container .inputs label {
  margin-right: 1em;
  margin-top: 8px;
  margin-bottom: 8px;
}

.container input[type="radio"] {
  position: absolute;
  opacity: 0;
}

.container input[type="radio"] + label {
  cursor: pointer;
}

.container input[type="radio"] + label:before {
  display: inline-block;
  content: "";
  width: 0.8em;
  height: 0.8em;
  position: relative;
  top: 0.25em;
  border-radius: 100%;
  background: #e6e7e8;
  background: var(--color-radio-background);
  border: 0.3em solid;
  border-color: #e6e7e8;
  border-color: var(--color-radio-background);
  margin-right: 0.4em;
}

.container input[type="radio"]:checked + label:before {
  background: #df4661;
  background: var(--color-radio-dot);
}

.container input[type="radio"]:focus-visible + label {
  outline: 2px solid;
  outline-color: #63b1bc;
  outline-color: var(--color-slider-thumb);
  border-radius: 3px;
}

@media screen and (max-width: 1024px) {
  .container {
    justify-content: center;
  }

  .container .title {
    width: 100%;
    text-align: center;
    margin-bottom: 0;
    margin-right: 0;
  }
}
