body {
  margin: 0;
}

.container {
  --color-background: #ffffff;
  --color-font-primary: #333333;
  --color-font-secondary: #253746;

  --color-input-font: #ffffff;
  --color-input-background: #253746;

  --color-slider-thumb: #63b1bc;
  --color-slider-background: #e6e7e8;

  --color-radio-dot: #df4661;
  --color-radio-background: #e6e7e8;

  --font-size--smallest: min(0.75rem, 36* 1px);
  --font-size--small: min(0.875rem, 42* 1px);
  --font-size--regular: min(1rem, 48* 1px);
  --font-size--medium: min(1.125rem, 54* 1px);
  --font-size--medium-large: min(calc(2px + 1.125rem), 56* 1px);
  --font-size--large: min(calc(4px + 1.125rem), 58* 1px);
  --font-size--larger: min(calc(9px + 1.0625rem), 60* 1px);
  --font-size--xlarge: min(calc(11px + 1.0625rem), 62* 1px);
  --font-size--huge: min(calc(19px + 0.9375rem), 64* 1px);
  --font-size--largest: min(calc(35px + 0.3125rem), 50* 1px);
  --font-size--display: min(calc(56px + 0.5rem), 80* 1px);
  --font-size-eika--small: min(calc(0.5px + 1.15625rem), 56* 1px);
  --font-size-eika--large: min(calc(6px + 1.125rem), 60* 1px);
  --font-size-eika--larger: min(calc(14px + 1rem), 62* 1px);
  --font-size-eika--xlarge: min(calc(16px + 1rem), 64* 1px);
  --font-size-eika--largest: min(calc(29px + 0.8125rem), 68* 1px);

  font-size: 16px;
  font-family: "Open Sans", Arial, sans-serif;
  text-align: center;

  padding: 0 0.625em;
  margin: 0 0 4em;
  color: #333333;
}

/*  */
/* WHEN BUILDING FOR EXPORT DISABLE THIS: */

/* .container {
  background: #f2f0ef;
  display: block;
  padding: 10em 5em;
  margin: 0 auto;
}
.container .card {
  max-width: 60em;
  margin: 0 auto;
} */

/*  */
/* AND ENABLE THIS: */
/*  */

.container {
  background: none;
  margin: 0;
  padding: 0;
}

/*  */
/*  */

.container .card {
  background: #fff;
  background: var(--color-background);
  border-radius: 0.375em;
  padding: 2.5em;
  padding-top: 1em;

  display: flex;
  flex-direction: column;
  align-items: center;
}

.container .heading {
  font-weight: 600;
  font-size: var(--font-size-eika--largest);
  margin: 1em 0.5em 0.3em;
  line-height: 1.3;
}

.container .description {
  max-width: 40em;
  text-align: center;
  font-size: var(--font-size--regular);
}

.result .header {
  font-size: var(--font-size--medium);
  font-weight: bold;
  margin: 0.3em 0;
  color: #253746;
  color: var(--color-font-secondary);
}

.result .finalAmount {
  font-size: var(--font-size-eika--largest);
  font-weight: 700;
  margin: 0.2em 0 0.4em;
}

.result .finalAmount .star {
  font-size: 0.7em;
}

.result .termsNote {
  max-width: 40em;
  text-align: left;
  font-size: var(--font-size--smallest);
  color: #253746;
  color: var(--color-font-secondary);
}

.result .termsNote .bold {
  font-weight: 700;
}

@media screen and (max-width: 1024px) {
  .container .heading {
    font-size: var(--font-size-eika--larger);
    margin-bottom: 0;
  }
  .result {
    margin-top: 1em;
  }
  .result .header {
    font-size: var(--font-size--medium);
  }
  .result .finalAmount {
    font-size: var(--font-size-eika--larger);
    margin-bottom: 1.5rem;
  }
}

@media screen and (max-width: 650px) {
  .container .card {
    padding-left: 1.5em;
    padding-right: 1.5em;
  }
  .container .description {
    margin-bottom: 0;
  }
}

/*  */
/* WHEN BUILDING FOR EXPORT DISABLE THIS: */

/* @media screen and (max-width: 1024px) {
  .container {
    padding-left: 2em;
    padding-right: 2em;
  }
}
@media screen and (max-width: 650px) {
  .container {
    padding-left: 0;
    padding-right: 0;
  }
} */

/*  */
/*  */
