.container {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  width: 100%;
  padding: 0 2em;
  text-align: right;
  margin-bottom: 0.5em;
}

.label {
  display: block;
  font-size: var(--font-size--medium);
  font-weight: bold;
  min-width: 10rem;
  margin-bottom: 0.8em;
  margin-right: 1em;
  margin-top: 0.7em;
}

.label .subLabel {
  display: block;
  font-size: var(--font-size--smallest);
  font-weight: 400;
  color: #253746;
  color: var(--color-font-secondary);
}

.numberInputContainer {
  display: block;
}

.numberInputContainer:after {
  font-family: "Open Sans", Arial, sans-serif;
  content: "kr";
  position: absolute;
  font-size: var(--font-size--regular);
  color: #ffffff;
  color: var(--color-input-font);
  font-weight: bold;
  transform: translate(-24px, 9px);
  line-height: 20px;
}

input.numberInput {
  font-family: "Open Sans", Arial, sans-serif;
  font-size: 18px;
  height: 20px;
  border: none;
  border-radius: 0.375em;
  background: #253746;
  background: var(--color-input-background);
  color: #ffffff;
  color: var(--color-input-font);
  font-weight: bold;
  max-width: 7em;
  text-align: right;
  padding: 8px 36px 8px 8px;
  margin-bottom: 1em;
  line-height: 18px;
}

.sliderContainer {
  display: block;
  flex-grow: 1;
  min-width: 10rem;
  margin-top: 0.5em;
  margin-right: 2em;
  margin-bottom: 1.5em;
}

.track {
  display: block;
  background: #e6e7e8;
  background: var(--color-slider-background);
  height: 0.7em;
  border-radius: 1em;
}

.thumb {
  height: 2em;
  width: 2em;
  background: #63b1bc;
  background: var(--color-slider-thumb);
  border-radius: 50%;
}

@media screen and (max-width: 1024px) {
  .container {
    margin-bottom: 0;
  }

  .label {
    width: 100%;
    text-align: center;
    margin-bottom: 0.6em;
    margin-right: 0;
  }

  .subLabel {
    font-weight: 400;
  }
}

@media screen and (max-width: 650px) {
  .sliderContainer {
    width: 100%;
    margin-right: 0;
    order: 2;
  }

  .numberInputContainer {
    order: 1;
  }
}
